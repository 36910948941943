import { Grid, Row, Col } from '@freecodecamp/react-bootstrap';
import './about.css'
import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// import Banner from '../components/landing/components/banner';
// import Feature from '../components/landing/components/feature';
// import Process from '../components/landing/components/process';
// import Partner from '../components/landing/components/partner';
// import Try from '../components/landing/components/try';
// import Certifications from '../components/landing/components/certifications';
import Header from '../components/landing/components/header';
import Footer from '../components/landing/components/footer';

function PrivacyPage() {

  return (
    <>
      <Helmet>
        <title>Extraise</title>
        <link rel="shortcut icon" href="/assets/images/logo/favourite_icon_1.png" />
        <link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/icomoon.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/themify-icons.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/fontawesome-all.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/owl.carousel.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/owl.theme.default.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/aos.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/animate.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/splitting.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/magnific-popup.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/style.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/_extraise.css" />
        <script defer key="jquery" src="/assets/js/jquery-3.4.1.min.js" />
        <script defer key="popper" src="/assets/js/popper.min.js" />
        <script defer key="bootstrap" src="/assets/js/bootstrap.min.js" />
        <script defer key="owl-carousel" src="/assets/js/owl.carousel.min.js" />
        <script defer key="aos" src="/assets/js/aos.js" />
        <script defer key="splitting" src="/assets/js/splitting.js" />
        <script defer key="magnific-popup" src="/assets/js/magnific-popup.min.js" />
        <script defer key="isotope" src="/assets/js/isotope.pkgd.min.js" />
        <script defer key="masonry" src="/assets/js/masonry.pkgd.min.js" />
        <script defer key="imagesloaded" src="/assets/js/imagesloaded.pkgd.min.js" />
        <script defer key="parallax" src="/assets/js/parallax.min.js" />
        <script defer key="parallax-scroll" src="/assets/js/parallax-scroll.js" />
        <script defer key="gmaps" src="/assets/js/gmaps.js" />
        <script defer key="mCustomScrollbar" src="/assets/js/mCustomScrollbar.js" />
        <script defer key="custom" src="/assets/js/custom.js" />
      </Helmet>
      <Header />

      {/* <section id="breadcrumb_section" className="breadcrumb_section bg_gradient_blue deco_wrap d-flex align-items-center text-white clearfix" style={{ minHeight: "auto", padding: "55px 0" }}>
        <div className="container">
          <div className="breadcrumb_content text-center" data-aos-delay={100}>
            <h1 className="page_title" style={{ fontSize: "25px" }}>Pricing Plans</h1>
            <p className="mb-0">
              Stay ahead of the curve with
              Extraise Pro.
            </p>
          </div>
        </div>
        <div className="deco_image spahe_1" data-aos="fade-down" data-aos-delay={300}>
          <img src="/assets/images/shapes/shape_1.png" alt="spahe_not_found" />
        </div>
        <div className="deco_image spahe_2" data-aos-delay={400}>
          <img src="/assets/images/shapes/shape_2.png" alt="spahe_not_found" />
        </div>
      </section> */}
      <main>
        <section id="banner_section" className="banner_section software_banner deco_wrap d-flex align-items-center text-white clearfix" style={{ minHeight: "400px", backgroundColor: "#fbfbfd", paddingBottom: "220px", paddingTop: "200px" }} >
          <div className="container">
            <div className="row align-items-center justify-content-lg-between">

              <div className="col-lg-7">
                <div className="banner_content c_slide_in">
                  <h1 className="title_text mb-30 text_effect_wrap text_effect_wrap">
                    {/* <span className="text_effect text_effect_left">
                      About us
                    </span><br /> */}
                    <span className="text_effect text_effect_left">

                      Extraise Platform - Privacy Policy
                    </span>
                  </h1>
                  <p className="mb-50" data-aos="fade-up" data-aos-delay={200}>
                    { } <br /><small>{ }</small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="deco_image bg_shape_1" data-background="/assets/images/banner/10_software/bg_1.png" />
          <div className="deco_image bg_shape_2" data-background="/assets/images/banner/10_software/bg_2.png" style={{ backgroundSize: "cover" }} />
          <div className="deco_image bg_shape_3" data-background="/assets/images/banner/10_software/bg_3.png" data-aos="zoom-out" data-aos-delay={100} />
          <div className="deco_image shape_1" data-aos="fade-left" data-aos-delay={500}>
            <img src="/assets/images/banner/10_software/shape_1.png" alt="shape_not_found" />
          </div>
          <div className="deco_image shape_2" data-aos="fade-right" data-aos-delay={700}>
            <img src="/assets/images/banner/10_software/shape_2.png" alt="shape_not_found" />
          </div>
          <div className="deco_image cloud_1" data-aos="fade-up" data-aos-delay={500}>
            <img src="/assets/images/banner/10_software/cloud_1.png" alt="shape_not_found" />
          </div>
          <div className="deco_image cloud_2" data-aos="fade-up" data-aos-delay={700}>
            <img src="/assets/images/banner/10_software/cloud_1.png" alt="shape_not_found" />
          </div>
        </section>

        <section className="about-section banner_section software_banner d-flex align-items-center clearfix" style={{ minHeight: "400px", backgroundColor: "#fbfbfd" }}>
          <div className="container">
            <div className="row">
              <div className="content-column col-md-12 col-sm-12 order-2">
                <div className="inner-column">
                  <div className="text">
                    Welcome to Extraise, the online platform dedicated to providing frontend development training. We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how Extraise collects, uses, and safeguards your data. By using our platform, you agree to the terms outlined below.
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="sec-title">
                <h2>1. Information We Collect</h2>
              </div>
              <div className="text">
                <b>Account Information:</b>
                When you create an account on Extraise, we collect your name, email address, and any other information you provide during the registration process.
              </div>
              <div className="text">
                <b>Payment Information:</b>
                If you make a purchase on Extraise, we collect payment information, such as credit card details or other payment methods.
              </div>
              <div className="text">
                <b>Usage Information:</b>
                We collect information about your interactions with the Extraise platform, including the courses you enroll in, progress, quizzes, and other user activities.
              </div>
              <div className="text">
                <b>Device and Log Information:</b>
                We collect information about the device you use to access Extraise, including IP address, browser type, operating system, and log information.
              </div>
            </div>
            <div className="column">
              <div className="sec-title">
                <h2>2. How We Use Your Information</h2>
              </div>
              <div className="text">
                <b>Provide and Improve Services:</b>
                We use your information to provide, personalize, and improve our services, including course recommendations, user support, and platform enhancements.
              </div>
              <div className="text">
                <b>Communication:</b>
                We may use your email address to send you important updates, announcements, and information related to your account.
              </div>
              <div className="text">
                <b>Payment Processing:</b>
                Your payment information is used to process transactions and subscriptions securely.
              </div>
              <div className="text">
                <b>Security and Compliance:</b>
                We use information to monitor and enhance the security of our platform and to comply with legal and regulatory requirements.
              </div>
            </div>
            <div className="column">
              <div className="sec-title">
                <h2>3. Cookies and Tracking Technologies</h2>
              </div>
              <div className="text">
                We use cookies and similar tracking technologies to enhance your experience on Extraise, analyze usage patterns, and personalize content. You can manage your cookie preferences through your browser settings.
              </div>
            </div>
            <div className="column">
              <div className="sec-title">
                <h2>4. Data Sharing</h2>
              </div>
              <div className="text">
                We do not sell, trade, or rent your personal information to third parties. We may share information with trusted service providers who assist us in operating the platform, conducting business, or servicing you, as long as they agree to keep this information confidential.
              </div>
            </div>
            <div className="column">
              <div className="sec-title">
                <h2>5. Data Security</h2>
              </div>
              <div className="text">
                Extraise employs industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.
              </div>
            </div>
            <div className="column">
              <div className="sec-title">
                <h2>6. Your Choices</h2>
              </div>
              <div className="text">
                You have the right to access, correct, or delete your personal information. You may also choose to unsubscribe from promotional emails. Please contact us at info@extraise.com to exercise these rights.
              </div>
            </div>
            <div className="column">
              <div className="sec-title">
                <h2>7. Changes to Privacy Policy</h2>
              </div>
              <div className="text">
                Extraise reserves the right to update or modify this Privacy Policy at any time. We will notify you of significant changes, and your continued use of the platform after such changes constitutes acceptance of the updated policy.            </div>
            </div>
            <div className="column">
              <div className="sec-title">
                <h2>8. Contact Us</h2>
              </div>
              <div className="text">
                If you have any questions or concerns regarding our Privacy Policy, please contact us at info@extraise.com.
              </div>
              <div className="text">
                Thank you for choosing Extraise. We are committed to providing you with an excellent learning experience while prioritizing the security and privacy of your information.
              </div>
            </div>
          </div>
        </section>
        {/* <Feature /> */}
        {/* <Process /> */}
        {/* <Partner /> */}
        {/* <Try /> */}

      </main>
      <Footer />
    </>
  );
}

PrivacyPage.displayName = 'privacy-policy';

export default connect(null)(PrivacyPage);
